<template>
<base-popup
    :popup-model="show"
    :title="`Manual Payment - ${currentItem.refcode}`"
    :valid="!valid"
    @onSaveButtonClick="manualPaymentHandler"
    @onCancelButtonClick="closePopupHandler"
>
  <v-form
      :ref="formRef"
      v-model="valid"
      lazy-validation
  >
    <v-row class="mt-8 mb-3 mr-3">
      <v-col cols="12" class="mt-0 mb-0 ml-0 pt-0 pb-0 pl-0">
        <g-p-auto-complete
            :dense="true"
            :outlined="true"
            icon="mdi-contacts"
            search-label="Select Method"
            :search-value="currentItem.methods"
            :status=true
            @selectSearchResult="onSelections('paymentMethod',$event)"
            @clearSearchResult="handleClearSearchResult('paymentMethod')"
            apiPath="methods"
        />
      </v-col>
      <v-col cols="12" class="mt-0 mb-0 ml-0 pt-0 pb-0 pl-0">
        <v-text-field
            label="Transaction No."
            prepend-icon="mdi-cog-transfer"
            v-model="currentItem.trxnId"
            dense
            outlined
        />
      </v-col>
      <v-col cols="12" class="mt-0 mb-0 ml-0 pt-0 pb-0 pl-0">
         <v-text-field
             label="Mobile"
             prepend-icon="mdi-cellphone-basic"
             v-model="currentItem.phone"
             dense
             outlined
         />
      </v-col>
      <v-col cols="12" class="mt-0 mb-0 ml-0 pt-0 pb-0 pl-0">
        <v-text-field
            class="right-align-text"
            prepend-icon="mdi-cash"
            label="Total Amount"
            type="number"
            v-model="currentItem.totalAmount"
            dense
            outlined
            disabled
        />
      </v-col>
      <v-col cols="12" class="mt-0 mb-0 ml-0 pt-0 pb-0 pl-0">
        <v-text-field
            class="right-align-text"
            prepend-icon="mdi-cash"
            label="Payment Fess"
            type="number"
            v-model="paymentFees"
            :min="0"
            dense
            outlined
            onclick="this.select()"
        />
      </v-col>
      <v-col cols="12" class="mt-0 mb-0 ml-0 pt-0 pb-0 pl-0">
        <v-text-field
            class="right-align-text"
            prepend-icon="mdi-cash"
            label="Gross Total"
            type="number"
            v-model="grossTotal"
            dense
            outlined
            disabled
        />
      </v-col>
      <v-col cols="12" class="mt-0 mb-0 ml-0 pt-0 pb-0 pl-0">
        <v-file-input
            label="Attachment"
            prepend-icon="mdi-camera"
            accept="image/*"
            dense
            outlined
            @change.native="onChangeImageHandler"
            @click:clear="onClearHandler"
        />
        <span v-if="error.imageError" class="error-message">{{error.imageError}}</span>
        <v-img  v-if="fileUrl.imageUrl" :src="fileUrl.imageUrl" aspect-ratio="2" contain/>
      </v-col>
    </v-row>
  </v-form>
</base-popup>
</template>

<script>
import BasePopup from "./BasePopup";
import Popup from "../mixins/Popup";
import GPAutoComplete from "../GPAutoComplete";

export default {
  name: "ManualPaymentPopup",
  mixins: [Popup],
  components: {GPAutoComplete, BasePopup},
  data() {
    return {
      paymentFees: 0.00,
    }
  },
  computed: {
    grossTotal: {
      get() {
        let paymentFees = isNaN(this.paymentFees) ? this.paymentFees : 0;

        if(parseFloat(this.currentItem.totalAmount) < parseFloat(paymentFees)) {
          this.paymentFees = 0;
          return this.currentItem.totalAmount;
        }
        return (parseFloat(this.currentItem.totalAmount) - parseFloat(paymentFees)).toFixed(2);
      },
      set(totalAmount) {
        return totalAmount;
      }
    }
  },
  methods: {
    manualPaymentHandler() {
      if (!this.isFormValid) {
        return;
      }

      if(typeof this.currentItem.paymentMethod === 'object') {
        this.currentItem.paymentMethod = this.currentItem.paymentMethod.id;
      }

      this.$emit('saveItemHandler', {...this.currentItem});
      this.closePopupHandler();
    }
  }
}
</script>

<style scoped>

</style>