import {mapState} from "vuex";
import _ from "lodash";
import moment from "moment";
import Props from "./Props";
import Data from "./Data";
import pTable from "../../../json/pTable.json";

export default {
    name: "ListPageMixin",
    mixins: [Props, Data],
    watch: {
        pagination: {
            handler() {
                this.loadData();
            },
            deep: true
        }
    },
    mounted() {
        this.loadData();
    },
    created() {
        if (this.actionButtons && this.actionButtons.length) {
            const actionButtons = this.actionButtons.map(button => {

                if (button.clickHandler) {
                    button.clickHandler = this[button.clickHandler];
                }
                return button;
            });

            this.defaultActionButtons = this.showDefaultActionButtons ? this.defaultActionButtons.concat(actionButtons) : [...actionButtons];
        }
    },
    computed: {
        ...mapState({
            data: function (state) {
                const stateData = this.stateendpoint.split('.').reduce((p, c) => p && p[c] || null, state);
                this.total = stateData.total;
                this.totalAmount = stateData.totalAmount ? stateData.totalAmount: 0.00;

                return stateData.data;
            },
            user: state => state.auth.user,
            allAdminUsers: state => state.allAdminUsers,
            allPayMethods: state => state.method.allPayMethods,
            actionColumns() {
                return _.find(this.columns, {value: 'action'}) ? this.defaultActionButtons : []
            }
        }),
        getPayStatus() {
            if(this.showInvoiceButton) return this.payStatus;
            return this.shantoitPayStatus;
        },
    },
    methods: {
        openPopUp() {
            this.showPopup = true;
        },
        closePopupHandler() {
            this.showPopup = false;
            this.showFilesPopup = false;
            this.showLinkPopup = false;
            this.showManualPaymentPopup = false;
            this.showCounterLinkPopup = false;
            this.showEmailTimeliner = false;

            setTimeout(() => {
                this.editedItem = {};
                this.editedIndex = -1;
            }, 300);
        },
        async deleteItem(item, confirm = false) {
            if (!confirm) {
                this.currentSelectedItem = item;
                this.showDeleteConfirm = true;
                return;
            }
            this.showDeleteConfirm = false;
            const id = item.id;
            await this.handleDestroy(this.actions.remove, id);
        },
        async removePostItem(item, confirm = false) {
            if (!confirm) {
                this.currentSelectedItem = item;
                this.showRemoveConfirm = true;
                return;
            }
            this.showRemoveConfirm = false;
            const id = item.id;
            await this.handleDestroy(this.actions.postRemove, id);
        },
        async syncItem(item, confirm = false) {
            if (!confirm) {
                this.currentSelectedItem = item;
                this.showConfirm = true;
                return;
            }
            this.showConfirm = false;
            const id = item.id;
            await this.handleDestroy(this.actions.syncItem, id);
        },
        async syncItemMoz(item, confirm = false) {
            if (!confirm) {
                this.currentSelectedItem = item;
                this.showConfirmMoz = true;
                return;
            }
            this.showConfirmMoz = false;
            const id = item.id;
            await this.handleDestroy(this.actions.syncItemMoz, id);
        },
        async destroyItem(item, confirm = false) {
            if (!confirm) {
                this.currentSelectedItem = item;
                this.showDeleteForeverConfirm = true;
                return;
            }
            this.showDeleteForeverConfirm = false;
            const id = item.id;
            await this.handleDestroy(this.actions.destroy, id);
        },
        async deleteWithoutItem(item, confirm = false) {
            if (!confirm) {
                this.currentSelectedItem = item;
                this.showDeleteWithoutPostConfirm = true;
                return;
            }
            this.showDeleteWithoutPostConfirm = false;
            const id = item.id;
            await this.handleDestroy(this.actions.deleteWithoutPost, id);
        },
        async refundInvoiceHandler(item, confirm = false) {
            if (!confirm) {
                this.currentSelectedItem = item;
                this.showRefundConfirm = true;
                return;
            }
            this.showRefundConfirm = false;
            const id = item.id;
            await this.handleDestroy(this.actions.refund, id);
        },
        async handleDestroy(actionHook, id) {
            this.$store.dispatch(actionHook, {id})
                .then((response) => {
                    const {data} = response;
                    this.setSnackBar({text: data.message, type: 'info'})
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.setSnackBar({
                            type: 'error',
                            text: error.response.data.errors["message"][0]
                        });
                    }
                    this.setSnackBar({text: 'Data Validation Failed', type: 'error'})
                })
                .finally(() => {
                    this.currentSelectedItem = null;
                    this.loadData();
                });
        },
        editItem(item) {
            this.editedIndex = this.data.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.showPopup = true;
        },
        viewEmailDetails(item) {
            this.editedIndex = this.data.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.showEmailTimeliner = true;
        },
        linkHandler(item) {
            this.editedIndex = this.data.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.showLinkPopup = true;
        },
        counterLinkHandler(item) {
            this.editedIndex = this.data.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.showCounterLinkPopup = true;
        },
        fileHandler(item) {
            this.editedIndex = this.data.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.showFilesPopup = true;
        },
        getPagingData() {
            let {page, itemsPerPage, sortBy, sortDesc} = this.pagination;
            return {
                page,
                rowsPerPage: itemsPerPage,
                sortBy: sortBy ? sortBy[0] : '',
                descending: sortDesc ? sortDesc[0] : '',
                s: '',
                startDate: this.startDate,
                endDate: this.endDate,
                user: this.searchUser,
                paymentStatus: this.paymentStatus,
                payMethod: this.payMethod,
                paymentEmail: this.paymentEmail,
                contactMail: this.contactMail,
                mailType: this.mailType,
                param1: this.param1,
                billingType: this.billingType
            };
        },
        async saveItemHandler(item) {
            if (this.editedIndex > -1) {
                await this.handleItem(this.actions.update, item);
            } else {
               await this.handleItem(this.actions.create, item);
            }
        },
        async handleItem(actionHook, item, isReload = true) {
            this.$store.dispatch(actionHook, item)
                .then((response) => {
                    const {data} = response;
                    this.isNewsletterBtnLoading = false
                    this.setSnackBar({text: data.message, type: 'info'})
                })
                .catch((error) => {
                    this.isNewsletterBtnLoading = false
                    if(error.response.data.errors && error.response.data.errors.message[0]) {
                        this.setSnackBar({text: error.response.data.errors.message[0], type: 'error'});
                        return;
                    } else if(error.response.data.errors && (error.response.data.errors.startDate[0] || error.response.data.errors.endDate[0])) {
                        this.setSnackBar({text: 'The date field is required', type: 'error'});
                        return;
                    }
                    this.setSnackBar({text: 'Data Validation Failed', type: 'error'})
                })
                .finally(() => {
                   if(isReload) this.loadData();
                    this.hideLoading();
                });
        },
        async switchHandler({item, eventStatus}) {
           await this.handleItem(this.actions.update, {...item, isActive: eventStatus});
        },
        async switchMethodHandler({item, eventStatus}) {
            await this.handleItem(this.actions.update, {...item, isByDefault: eventStatus});
        },
        async switchBrokenHandler({item, eventStatus}) {
            await this.handleItem(this.actions.update, {...item, isCheckBrokenLink: eventStatus});
        },

        handleSearch(searchTerm) {
            this.searchTerm = searchTerm.replace(/[\u200B-\u200D\uFEFF]/g, '');

            this.loadData(true);
        },

        loadData(resetPaging = false) {

            let pagingData = this.getPagingData();
            if (resetPaging) {
                pagingData.page = 1;
            }

            pagingData.s = this.searchTerm || '';
            if (!_.isEmpty(pagingData.s)) {
                let queryLength = pagingData.s.length;
                if ((queryLength < this.searchLength) || (queryLength > this.searchLimit)) return;
            }

            if (this.isLoading || !this.actions.load) return;
            this.showLoading();

            this
                .$store
                .dispatch(this.actions.load, {...pagingData})
                .catch(err => {
                    console.log(err)
                })
                .finally(() => (this.hideLoading()))
        },
        showLoading() {
            this.isLoading = true;
        },
        hideLoading() {
            this.isLoading = false;
        },
        handleActionButtonClick(data) {
            const actionButton = this.defaultActionButtons.find((button) => button.category === data.category);

            actionButton.clickHandler(data.row);
        },
        setSnackBar({text, type}) {
            this.$store.commit('SET_SNACKBAR', {text, type});
        },
        getItemStatus(status) {
            if (status === 1) {
                return 'Yes';
            }
            return 'No';
        },
        openSite(site) {
            window.open(`http://www.${site}`, '_blank');
        },
        getColor(status) {
            const _status = status.toLowerCase();

            if (_status === "completed") {
                return "#4CAF50";
            } else if (_status === "accepted") {
                return "#283593";
            } else if (_status === "rejected") {
                return "#FFC107";
            } else {
                return "#1565C0";
            }
        },
        getDate(date) {
            if (date) return moment(date).format('MMM D,  YYYY');
            return '';
        },
        editInvoiceHandler(item) {
            this.$router.push({path: `/edit-invoice/${item.id}`});
        },
        liveLinkReplacementHandler(item) {
            this.$router.push({path: `/live-link-replacement/${item.id}`});
        },
        manualPaymentHandler(item) {
            this.editedIndex = this.data.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.showManualPaymentPopup = true;
        },
        copyTemplateHandler(item) {
            this.getMailTemplate(item);
        },
        async resendPaymentMailHandler(item, confirm=false) {
            if (!confirm) {
                this.currentSelectedItem = item;
                this.showResendMailConfirm = true;
                return;
            }
            this.showResendMailConfirm = false;

            this.showLoading();

            await this.handleItem(this.actions.paymentMailHandler, {id: item.id}, false)
        },
        async reminderPaymentMailHandler(item, confirm = false) {
            if (!confirm) {
                this.currentSelectedItem = item;
                this.showReminderMailConfirm = true;
                return;
            }
            this.showReminderMailConfirm = false;

            await this.handleItem(this.actions.reminderMail, {id: item.id});
        },
        async afterReminderPaymentMailHandler(item, confirm = false) {
            if (!confirm) {
                this.currentSelectedItem = item;
                this.showAfterReminderMailConfirm = true;
                return;
            }
            this.showAfterReminderMailConfirm = false;

            await this.handleItem(this.actions.afterReminderMail, {id: item.id});
        },
        editSalesCounterHandler(item) {
            this.$router.push({path: `/edit-sale-counter/${item.id}`});
        },
        async restoreHandler(item, confirm = false) {
            if (!confirm) {
                this.currentSelectedItem = item;
                this.showRestoreConfirm = true;
                return;
            }
            this.showRestoreConfirm = false;

            await this.handleItem(this.actions.restore, item);
        },
        async onResetInvoiceHandler(item, confirm = false) {
            if (!confirm) {
                this.currentSelectedItem = item;
                this.showResetInvoiceConfirm = true;
                return;
            }
            this.showResetInvoiceConfirm = false;

            await this.handleItem(this.actions.reset, item);
        },
        async onResetResetWPAccessHandler(item, confirm = false) {
            if (!confirm) {
                this.currentSelectedItem = item;
                this.showResetWPAccess = true;
                return;
            }
            this.showResetWPAccess = false;

            await this.handleItem(this.actions.reset, item);
        },
        onChangeDateHandler(range) {
            const {startDate, endDate} = range;

            this.startDate = startDate;
            this.endDate = endDate;
            this.loadData(true);
        },
        onChangeFilterHandler(selection, handler) {
            if(!selection) {
                this[handler] = '';
            }
            this[handler] = selection;
            this.loadData(true);
        },
        async republishHandler(item, confirm = false) {
            if (!confirm) {
                this.currentSelectedItem = item;
                this.showRepublishConfirm = true;
                return;
            }
            this.showRepublishConfirm = false;

            await this.handleItem(this.actions.republish, item);
        },
        async onChangePaymentEmailHandler(tempType, confirm= false) {
            if(!tempType) {
                this.loadData(true);
                this.showMailConfirm = false;
                this.isNewsletterBtnLoading = false;
                return;
            }
            if(this.selected.length === 0 && !this.isSendMailToAll) {
                this.setSnackBar({text: 'No invoice are selected, Please select a invoice', type: 'info'});
                this.showMailConfirm = false;
                this.isNewsletterBtnLoading = false;
                return;
            }

            if(typeof tempType === 'object') {
                this.currentSelectedItem = tempType;
            } else {
                this.currentSelectedItem = this.mailTemplates.find((data) => data.id === tempType);
            }

            if (!confirm) {
                this.showMailConfirm = true;
                return;
            }

            this.showMailConfirm = false;

            const templateType = tempType.id;
            const selectedItems = this.selected.map((item) => item.id);
            this.selected = [];

            await this.handleItem(this.actions.sendMail, {selectedItems, templateType, mailer: this.mailer, isSendMailToAll: this.isSendMailToAll, mailType:this.param1});
        },
        async onChangedPrepaymentStatus(item, confirmed=false) {
            if (!confirmed) {
                this.currentSelectedItem = item;
                this.showPrepaymentConfirm = true;
                return;
            }

            this.showPrepaymentConfirm = false;

            await this.handleItem('changedPrepaymentStatus', {...item}, true);
        },
        salesDetailsHandler(item) {
             this.$router.push({
                 path: '/sale-counter-details/:id',
                 name: 'SaleCounterDetails',
                 params: {id: item.id}
             });
        },
        invoiceMailDetailsHandler(item) {
            this.$router.push({
                path: '/invoice-mail-details/:id',
                name: 'InvoiceMailDetails',
                params: {id: item.id}
            });
        },
        async newsLetterHandler() {
            if(!this.templateType && !this.mailer) return;
            this.isNewsletterBtnLoading = true;
            await this.onChangePaymentEmailHandler(this.templateType);
        },
        newsLetterTemplateHandler(selection) {
            if(selection) {
                this.templateType = selection;
            } else {
                this.templateType = null;
            }
        },
        async clearButtonHandler() {
           await this.handleItem(this.actions.clearData, {});
        },
        setGoogleSheetName(sheetType) {
           if(sheetType === 'premiumSites') return 'Premium Sites';
           return sheetType === 'googleSheet' ? 'Reseller Sheet' : 'Site Sheet';
        },
        getPermission(user) {
            return this.showInvoiceButton && this.paymentStatus === 100 && pTable.invoiceGrandTotal.find(item => item === user.email);
        },
        setLinkExpiryColor(date) {
            if (date) return moment(date).isSameOrAfter(moment().format('YYYY-MM-DD')) ? 'primary' : 'error';
            else return 'primary'
        },
        openNewTabHandler(link) {
            let url = link.replace(/[\u200B-\u200D\uFEFF]/g, '');
            window.open(url, '_blank').focus();
        },
        getDueStatus(dateTime) {
            let days = moment().diff(moment(dateTime), 'days');
            if(days > 4 && days < 7) {
                return 'Late Due';
            } else if(days > 7) {
                return 'Over Due';
            } else {
                return  '';
            }
        },
       async sortHandler (event) {
            if(!(this.disableDefaultFooter && this.disablePagination)) return;
            const movedItem = this.data.splice(event.oldIndex, 1)[0];
            this.data.splice(event.newIndex, 0, movedItem);
           /*console.log(this.data);*/
            let _data = this.data.map((item, key)=> {
                let i = Number(key) + 1;
                return {
                    ...item,
                    sortId: i
                }
            });
            await this.handleItem('bulkMethodUpdate', {methods: _data});
        },
    }
}